  import * as echarts from 'echarts'

  let ChartMarket = {
    page() { return this.el.dataset.page },
    mounted(){
      const ctx = this.el;

      this.handleEvent("chart-market-update", function(payload){
        let chartMarket = echarts.getInstanceByDom(ctx)

        if(chartMarket === undefined){
          chartMarket = echarts.init(ctx);

          window.addEventListener('resize',
            () => {
              chartMarket.resize({
                width: window.innerWidth * 0.95,
                height: window.innerHeight * 0.85
              });
            }
          );
        }

        chartMarket.setOption(
          {
            grid: {
              containLabel: true
            },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: false
                },
                restore: {},
                saveAsImage: {
                  show: true,
                  title: 'Save as Image',
                  name: 'myChart',
                  pixelRatio: 2
                }
              }
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: [0],
                start: 0,
                end: 100
              }
            ],
            legend: {
              left: 'left',
              data: [payload.symbol]
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                snap: true,
                type: 'cross'
              },
              valueFormatter: (value) => value.toFixed(2)
            },
            xAxis: {
              type: 'time'
            },
            yAxis: {
              type: 'value',
              scale: true,
              axisPointer: {
                snap: true
              }
            },
            series: [
              {
                name: payload.symbol,
                symbol: 'circle',
                symbolSize: 5,
                smooth: false,
                data: payload.data,
                type: 'line',
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(55,118,190,0.7)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(55,118,190,0.1)'
                    }
                  ])
                }
              }
            ]
          }
        );

        chartMarket.resize({
          width: window.innerWidth * 0.95,
          height: window.innerHeight * 0.85
        });

      })
    },
    disconnected(){
      window.ComponentShareData.showModal = false
    }
  }

  export default ChartMarket
